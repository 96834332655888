import * as React from "react"
import { Link } from 'gatsby';
import Layout from "../components/layout"
import Seo from "../components/seo"

import '../css/custom-css/custom-404.css'


const NotFoundPage = () => (

<div class="dlab-bnr-inr full-height overlay-black-dark">
    <div class="container">
        <div class="row max-w700 dlab-bnr-inr-entry dz_error-404-area align-m dz_error-404 text-white m-auto">
            <div class="col-lg-4 col-md-4 m-tb30">
                <div class="bg-white dz_error">
                    <span className="text-grey">404</span>
                </div>
            </div>
            <div class="col-lg-8 col-md-8 m-b30 text-left">
                <h2 class="error-head">Something went wrong !</h2>
                {/* <p class="font-16">We are sorry but it appears that the page you are looking for could not be found. We are working on it and we will get it fixed as soon as possible.</p> */}
                <p class="font-16">You can go back to the Main Page by clicking the button.</p>
                <Link to="/" class="site-button"><span className="text-black">Go To Home</span></Link>
            </div>
        </div>
    </div>
</div>
)

export default NotFoundPage
